@media screen and (min-width: 576px) {

    section {
        .textWrapper {
            .newsText {
                margin-bottom: 25px;
            }

            .socialMediaLinksWrapper {
                a {
                    display: inline-block;
                    margin: initial;

                    &:first-child {
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}
