@media screen and (min-width: 1200px) {

    nav {
        &.navbar {
            .container {
                max-width: 1320px;
                .row {
                    position: relative;
                }
            }
        }

        &.navbar-expand-lg .navbar-nav .nav-link {
            padding-right: 12px;
            padding-left: 12px;
        }

        .nav-item {
            .nav-link {
                font-size: 16px;
            }
        }
    }
}
