@media screen and (min-width: 992px) {
    section {
        .textImageWrapper {
            .imageWrapper {
                .underImage {
                    margin-top: -30px;
                }
            }
        }
    }
}
