@media screen and (min-width: 768px) {

    h1 {
        font-size: 36px;
    }

    h2 {
        font-size: 30px;
    }
}
