html, body {
    width: 100%;
    overflow-x: hidden;
}

h1 {
    font-family: "Inter Tight Regular", sans-serif;
    line-height: 1.5;
    font-size: 20px;
    text-transform: uppercase;
    color: $white;
    letter-spacing: 3px;
}

h2 {
    font-family: "Inter Tight Bold", sans-serif;
    line-height: 1.5;
    text-transform: uppercase;
    font-size: 28px;
    letter-spacing: 3px;
    margin-bottom: 50px;
}

strong {
    font-family: "Inter Tight Bold", sans-serif !important;
    font-weight: normal;
}

div, span, p, a, ul, li {
    font-family: "Inter Tight Light", sans-serif;
    letter-spacing: 1.44px;
    color: $blue;
    font-size: 16px;
    line-height: 1.6;
}

a {
    text-decoration: none;
}

ul {
    margin-bottom: 25px;
    list-style-type: "- ";
    padding-left: 15px;
}

section {
    padding: 100px 0;
}

.btn {
    border: none;
    border-radius: 0;
    padding: 5px 15px;

    img {
        width: 45px;
    }

    &:active {
        color: $lightBlue !important;
        box-shadow: none;
    }
}

.btn-primary {
    background-color: $white;
    color: $blue;
    text-transform: uppercase;

    &:active, &:hover {
        color: $blue !important;
        background-color: $beige !important;
        box-shadow: none;
    }
}

.btn-secondary {
    background-color: $blue;
    color: $white;
}
