@media screen and (min-width: 768px) {
    nav {
        .navbar-brand {
            img {
                width: 150px;
            }
        }

        .navbar-toggler {
            margin: -45px 0 0 auto;
        }
    }
}
