@media screen and (min-width: 768px) {
    section {
        .textImageWrapper {
            .textWrapper {
                padding-right: 25px;

                &.pLeft {
                    padding-right: 15px;
                    padding-left: 25px;
                }
            }
            .imageWrapper {
                padding-left: 25px;

                &.pRight {
                    padding-left: 15px;
                    padding-right: 25px;
                }
            }
        }

        .accordionWrapper {
            .card-header {
                padding: 12px 20px;

                h3 {
                    button {
                        letter-spacing: 1.44px;

                        &:after, &.minus:after {
                            right: 20px;
                        }

                        &:after {
                            top: 18px;
                        }

                        &.minus:after {
                            top: 10px;
                        }
                    }
                }
            }
        }
    }
}
