@media screen and (min-width: 992px) {
    nav {
        &.navbar {
            padding: 25px 0;
        }

        .container {
            max-width: 960px;
            .row {
                position: relative;
            }
        }

        .navbar-collapse {
            height: initial;
        }

        .navbar-nav {
            left: auto;
            right: 0;
            transform: translate(0, -50%);
        }

        .navbar-brand {
            width: initial;
            img {
                width: 200px;
            }
        }

        .nav-item {
            .nav-link {
                font-size: 16px;
                padding-left: 15px !important;
                padding-right: 15px !important;
            }
            &:last-child {
                .nav-link {
                    padding-right: 0 !important;
                }
            }
        }
    }
}
