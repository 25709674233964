section {
    .textWrapper {
        margin-bottom: 50px;

        span {
            display: block;
        }

        .newsText {
            text-transform: uppercase;
            font-size: 28px;
            margin-bottom: 50px;
        }

        .socialMediaLinksWrapper {
            a {
                display: block;
                margin: 0 auto 20px auto;
                width: fit-content;
                img {
                    margin-right: 15px;
                    height: 20px;
                    width: auto;
                    margin-top: -2px;
                }
            }
        }
    }

    .textImageWrapper {
        .textWrapper {
            margin-bottom: 50px;

            .text {
                .dateDescription {
                    margin-bottom: 50px;
                }

                .date {
                    margin-bottom: 25px;
                }

                .signature {
                    width: 200px;
                    margin-bottom: 10px;
                }
            }
        }

        .imageWrapper {
            position: relative;
            margin-bottom: 50px;
            .ctaButton {
                position: absolute;
                right: 0;
                bottom: 20%;

                img {
                    margin-left: 10px;
                }

                &.positionLeft {
                    right: auto;
                    left: 0;
                }
            }

            .upperImage {
                z-index: 10;
                position: relative;
            }

            .underImage {
                margin-top: -20px;
            }
        }
    }

    .accordionWrapper {
        .card {
            border: 0;
            border-radius: 0;
        }
        .card-header {
            background-color: $blue;
            color: $white;
            font-weight: bold;
            padding: .75rem;
            border-radius: 0;

            &:hover {
                cursor: pointer;
            }

            h3 {
                button {
                    color: $white;
                    text-transform: uppercase;
                    padding: 0;
                    font-size: 16px;
                    font-family: "Inter Tight Regular", sans-serif;
                    text-decoration: none;
                    letter-spacing: 0.5px;

                    &:focus, &:hover {
                        box-shadow: none;
                        text-decoration: none;
                    }

                    &:after {
                        content: url('/img/plus-weiss.svg');
                        position: absolute;
                        right: 0.75rem;
                        top: 16px;
                        width: 20px;
                    }

                    &.minus:after{
                        content: url('/img/minus-weiss.svg');
                        position: absolute;
                        right: 0.75rem;
                        top: 9px;
                        width: 20px;
                    }
                }
            }
        }

        .card-body {
            background-color: $lightBeige;
            border-radius: 0;
            p {
                color: $blue;
            }
        }
    }
}

.sectionOverlay {
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.colorBlue {
    color: $blue;
}

.colorWhite {
    color: $white;
}

.bgLightBlue {
    background-color: $lightBlue !important;
}

.bgBlue {
    background: rgb(29,33,45);
    background: linear-gradient(135deg, rgba(29,33,45,1) 0%, rgba(34,41,60,1) 100%);
}

.bgWhite {
    background-color: $white;
}

.bgBeige {
    background-color: $beige;
}

.bgLightBeige {
    background-color: $lightBeige;
}

.bgImage {
    background-image: url("/img/daniel-eiffler-auf-instagram-und-facebook-folgen.webp") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.subHeadline {
    font-family: "Inter Tight Regular", sans-serif;
    letter-spacing: 3.2px;
    font-size: 18px;
    text-transform: uppercase;
    display: block;
    margin-bottom: 1rem;
}

.quote {
    font-style: italic;
    margin-bottom: 25px;
    color: $beige;
    .author {
        font-size: 12px;
        color: $beige;
    }
}

.imprintSection {
    h1 {
        font-family: "Inter Tight Bold", sans-serif;
        word-wrap: break-word;
    }

    h2 {
        font-family: "Inter Tight Regular", sans-serif;
        font-size: 18px;
    }

    h3 {
        font-family: "Inter Tight Regular", sans-serif;
        font-size: 16px;
    }

    a {
        font-weight: bold;
        text-decoration: underline;

        &:hover {
            cursor: pointer;
        }
    }
}
