/* Inter Tight Light */
@font-face {
    font-family: 'Inter Tight Light';
    font-style: normal;
    src: local(''),
    url('/fonts/inter-tight-light.woff2') format('woff2');
}
/* Inter Tight Regular */
@font-face {
    font-family: 'Inter Tight Regular';
    font-style: normal;
    src: local(''),
    url('/fonts/inter-tight-regular.woff2') format('woff2');
}
/* Inter Tight Bold */
@font-face {
    font-family: 'Inter Tight Bold';
    font-style: normal;
    src: local(''),
    url('/fonts/inter-tight-bold.woff2') format('woff2');
}
