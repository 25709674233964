@media screen and (min-width: 768px) {

    header {
        .headerContentWrapper {
            .textWrapper {
                .textBox {
                    font-size: 18px;
                }
            }
        }
    }
}
