footer {

    .footerContentWrapper {
        padding: 30px 15px;
        .subNavWrapper {
            margin-bottom: 15px;

            a {
                color: $white;
                text-transform: uppercase;

                &:first-child {
                    margin-right: 20px;
                }
            }
        }

        .socialMediaWrapper {
            .socialMediaLink {
                background-color: $beige;
                padding: 10px;
                position: relative;
                width: 40px;
                height: 40px;
                display: inline-block;

                &:first-child {
                    margin-right: 15px;
                }

                img {
                    height: 25px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    .copyrightWrapper {
        padding: 10px;
    }
}
