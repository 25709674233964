@media screen and (min-width: 768px) {
    footer {
        .footerContentWrapper {
            padding: 20px 0;

            .socialMediaWrapper {
                .socialMediaLink {
                    &:first-child {
                        margin-right: 10px;
                    }
                }
            }
        }

        .container {
            .row {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}
