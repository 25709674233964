header {
    height: calc(100vh - 61px - 30px);
    background-image: url("/img/daniel-eiffler.webp") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;

    .headerContentWrapper {
        top: 52%;
        left:50%;
        transform: translate(-50%,-50%);

        .textWrapper {
            .textBox {
                margin: 0 auto;
                background-color: $blue;
                color: $white;
                font-size: 17px;
                padding: 10px 15px;
                width: fit-content;
            }
        }
    }

    .scrollDownWrapper {
        position: absolute;
        left: 50%;
        bottom: -22px;
        transform: translateX(-50%);

        &:hover {
            cursor: pointer;
        }

        img {
            width: 30px;
        }
    }
}
