@media screen and (min-width: 576px) {
    nav {
        .container {
            max-width: 100%;
            padding-left: 15px !important;
            padding-right: 15px !important;
        }
        .nav-item {
            .nav-link {
                font-size: 28px;
            }
        }
    }
}
