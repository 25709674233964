nav {
    background-color: $white;

    &.show {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 20;
        width: 100%;
    }

    &.navbar {
        padding: 10px 0;
        .container {
            .row {
                margin:0;
                width: 100%;
            }
        }
    }

    .navbar-collapse {
        height: calc(100vh - 61px);

        &.collapsing {
            height: calc(100vh - 61px) !important;
        }
    }

    .navbar-nav {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }

    .navbar-toggler {
        border:0;
        width: auto;
        display: inline-block;
        margin: -40px 0 0 auto;
        padding-right: 15px;

        &:focus {
            box-shadow: none;
        }
    }

    .navbar-brand {
        display: block;
        width: 100%;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-left: 15px;
        img {
            width:150px;
        }
    }

    .nav-item {
        text-align: center;
        .nav-link {
            text-transform: uppercase;
            color: $blue;
            font-size: 20px;
            font-family: "Inter Tight Light", sans-serif;
        }
    }
}


.animated-icon {
    width: 30px;
    height: 20px;
    position: relative;
    margin: 0;
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

.animated-icon span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

.animated-icon span {
    background: $blue;
}

.animated-icon span:nth-child(1) {
    top: 0;
    -webkit-transform-origin: left center;
    transform-origin: left center;
}

.animated-icon span:nth-child(2) {
    top: 10px;
    -webkit-transform-origin: left center;
    transform-origin: left center;
}

.animated-icon span:nth-child(3) {
    top: 20px;
    -webkit-transform-origin: left center;
    transform-origin: left center;
}

.animated-icon.open span:nth-child(1) {
    transform: rotate(45deg);
    top: 0;
    left: 8px;
}

.animated-icon.open span:nth-child(2) {
    width: 0;
    opacity: 0;
}

.animated-icon.open span:nth-child(3) {
    transform: rotate(-45deg);
    top: 21px;
    left: 8px;
}
